
import swal from 'sweetalert2';
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import Q from 'q';
import { IdName, SettingsProviderUser } from '@/models/Interfaces';
import { api } from '@/services/Api';

@Options({
    name: 'EditProviderUser',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        Multiselect
    },
    computed: {
        isNewUser() {
            return !this.id || this.id == '0';
        },
        userNameTitle() {
            if (this.id && this.id != '0') {
                return this.user.userName;
            }
            if (this.user.userName) {
                return this.user.userName;
            }
            return 'New User';
        },
        passwordIsValid() {
            if (!this.user.password) {
                return false;
            }
            if (this.user.password.length < 8) {
                return false;
            }
            if (!/[a-z]/.test(this.user.password)) {
                return false;
            }
            if (!/[A-Z]/.test(this.user.password)) {
                return false;
            }
            if (!/\d/.test(this.user.password)) {
                return false;
            }
            return true;
        }
    }
})
export default class EditProviderUser extends Vue {
    protected readonly id!: string;
    protected readonly passwordIsValid!: boolean;
    public user: SettingsProviderUser = {
        id: '',
        active: true,
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        profileId: null,
        userName: '',
        password: '',
        createdAt: null
    };
    public passwordFieldType = 'password';
    public profiles: IdName[] = [];
    public wrongProfile = false;
    public wrongEmail = false;
    public wrongMobile = false;
    public wrongUsername = false;
    public usernameExist = false;
    public emailExist = false;
    public saveWas = false;
    getCreateDateFormat(date: string) {
        const stillUtc = moment.utc(date).toDate();
        return moment(stillUtc)
            .local()
            .format('DD/MM/YYYY HH:mm');
    }
    async mounted() {
        swal.showLoading();
        if (this.id && this.id != '0') {
            const apiResult = await api.getUserData(this.id);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                this.user = apiResult.data.user;
            }
        }
        const response = await api.getProviderProfiles();
        if (response.errorMessage) {
            swal.fire({ icon: 'error', title: 'Oops...', text: response.errorMessage });
            return;
        }
        if (response.data) {
            this.profiles = response.data;
        }
        swal.close();
    }
    cancel() {
        this.$router.go(-1);
    }
    async save() {
        this.saveWas = true;
        let wasError = false;
        if (!this.user.firstName) {
            wasError = true;
        }
        if (!this.user.lastName) {
            wasError = true;
        }
        if (!this.user.mobile) {
            this.wrongMobile = true;
            wasError = true;
        } else {
            this.user.mobile = this.user.mobile.replace(/\D+/g, '');
        }
        if (!this.user.userName) {
            this.wrongUsername = true;
            wasError = true;
        }
        if (!this.passwordIsValid) {
            wasError = true;
        }
        if (!this.user.email) {
            this.wrongEmail = true;
            wasError = true;
        }
        if (!this.user.profileId) {
            this.wrongProfile = true;
            wasError = true;
        }
        if (wasError) {
            return;
        }
        swal.showLoading();
        this.wrongProfile = false;
        this.wrongEmail = false;
        this.wrongMobile = false;
        this.wrongUsername = false;
        this.usernameExist = false;
        this.emailExist = false;
        const apiResult = await api.saveUser(this.user);
        await Q.delay(400);
        if (apiResult.errorMessage) {
            swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
            return;
        }
        if (apiResult.data?.validationErrors && apiResult.data?.validationErrors.length > 0) {
            const errorList: string[] = [];
            apiResult.data?.validationErrors.forEach((element: string) => {
                switch (element) {
                    case 'profile':
                        this.wrongProfile = true;
                        errorList.push(this.$t('wrong.incorrect-profile'));
                        break;
                    case 'email':
                        this.wrongEmail = true;
                        errorList.push(this.$t('wrong.incorrect-email'));
                        break;
                    case 'mobile':
                        this.wrongMobile = true;
                        errorList.push(this.$t('wrong.incorrect-mobile'));
                        break;
                    case 'username':
                        this.usernameExist = true;
                        errorList.push(this.$t('wrong.incorrect-username'));
                        break;
                    case 'emailExist':
                        this.emailExist = true;
                        errorList.push(this.$t('wrong.email-exist'));
                        break;
                }
            });
            swal.fire({ icon: 'error', title: 'Oops...', html: errorList.join('<br/>') });
            return;
        }
        swal.fire({
            position: 'center',
            text: this.id && this.id != '0' ? this.$t('confirm.user-updated') : this.$t('confirm.user-created'),
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
        });
        this.cancel();
    }
}
